import React, {Component} from 'react';
import PropTypes from 'prop-types';

// alertify
import alertify from '../../../layout/lib/alertify';

// api
import api from '@matthahn/sally-fw/lib/api/lib/getEverything.lib.api';

// components
import Container from '@matthahn/sally-ui/lib/components/Grid/Container/Container';
import Row from '@matthahn/sally-ui/lib/components/Grid/Row/Row';
import Column from '@matthahn/sally-ui/lib/components/Grid/Column/Column';
import P from '@matthahn/sally-ui/lib/components/P/P';
import PageLoader from '../../../layout/components/PageLoader/PageLoader';

// Containers
import SelectServiceContainer from '../../../service/containers/SelectServiceContainer/SelectServiceContainer';

// driver api
import getDriverByIdApi from '../../../driver/api/getById.api.driver';
import listDriversApi from '../../../driver/api/list.api.driver';

// Error
import parseError from '@matthahn/sally-fw/lib/error/parseError';

// Events
import subscriptionHoc from '@matthahn/sally-fw/lib/event/hoc/subscription.hoc.event';
import showSelectServiceModalEvent from '../../../service/events/showSelectServiceModal.event.service';
import setVehicleHistoryIdEvent from '../../../vehicle/events/setVehicleHistoryId.event.vehicle';
import showVehicleHistoryEvent from '../../../vehicle/events/showVehicleHistory.event.vehicle';

// Home
import homeRoute from '../../../dashboard/pages/HomePage/route';

// item containers
import ChargeItemsContainer from '../../../item/containers/ChargeItemsContainer/ChargeItemsContainer';
import TicketItemsContainer from '../../../item/containers/TicketItemsContainer/TicketItemsContainer';

// item events
import showChargeModalEvent from '../../../item/events/showChargeModal.event.item';

// item lib
// import canChargeForItems from '../../../item/lib/canChargeForItems.lib.item';

// Lib
import blobToFile from '@matthahn/sally-fw/lib/lib/blobToFile';
import downloadFile from '@matthahn/sally-fw/lib/lib/downloadFile';
import fkOrId from '@matthahn/sally-fw/lib/lib/fkOrId';
import isTicketCheckoutable from '../../lib/isCheckoutable.lib.ticket';
import isTicketDeletable from '../../lib/isDeletable.lib.ticket';
import isTicketEditable from '../../lib/isEditable.lib.ticket';

// Notify
import notify from '@matthahn/sally-ui/lib/libs/notify';

// Origins
import getVehicleOrigin from '../../../vehicle/origin/lib/getOrigin.lib.origin.vehicle';

// Permissions
import approveCheckPermission from '../../permissions/approveTickets.permission.ticket';

// redux
import {connect} from 'react-redux';

// router
import {withRouter} from 'react-router-dom';

// Sockets
import ticketDeletedSocket from '../../sockets/deleted.socket.ticket';
import ticketUpdatedSocket from '../../sockets/updated.socket.ticket';

// tag containers
import TicketTagEditorContainer from '../../../tag/containers/TicketTagEditorContainer/TicketTagEditorContainer';

// ticket api
import approveTicketApi from '../../api/approve.api.ticket';
import deleteTicketApi from '../../api/delete.api.ticket';
import generateRepairChargeApi from '../../api/generateRepairCharge.api.ticket';
import generateTicketInvoiceApi from '../../api/generateInvoice.api.ticket';
import getTicketById from '../../api/getById.api.ticket';

// ticket components
import TicketActionsRow from '../../components/TicketActionsRow/TicketActionsRow';

// ticket containers
import ExternalIdContainer from '../ExternalIdContainer/ExternalIdContainer';
import ExternalInvoiceContainer from '../ExternalInvoiceContainer/ExternalInvoiceContainer';
import MileageContainer from '../MileageContainer/MileageContainer';
import NotesContainer from '../NotesContainer/NotesContainer';
import RepairImagesContainer from '../RepairImagesContainer/RepairImagesContainer';
import TicketActionsContainer from '../TicketActionsContainer/TicketActionsContainer';
import TicketAlertsContainer from '../TicketAlertsContainer/TicketAlertsContainer';
import TicketEditContainer from '../TicketEditContainer/TicketEditContainer';
import TicketMechanicsContainer from '../TicketMechanicsContainer/TicketMechanicsContainer';
import TicketPaymentOptionsContainer from '../TicketPaymentOptionsContainer/TicketPaymentOptionsContainer';

// ticket events
import showMileageEditModalEvent from '../../events/showMileageEditModal.event.ticket';
import showTicketPaymentOptionsModalEvent from '../../events/showTicketPaymentOptionsModal.event.ticket';

// ticket permissions
import destroyTicketPermission from '../../permissions/destroy.permission.ticket';
import readTicketPermission from '../../permissions/read.permission.ticket';
import updateTicketPermission from '../../permissions/update.permission.ticket';

// ticketNote containers
import TicketNotesContainer from '../../../ticketNote/containers/TicketNotesContainer/TicketNotesContainer';

// vehicle
import getVehicleByIdApi from '../../../vehicle/api/getById.api.vehicle';
import getGeorgeVehicleByIdApi from '../../../vehicle/api/getGeorgeVehicleById.api.vehicle';

// vehicle container
import VehicleSummaryContainer from '../../../vehicle/containers/VehicleSummaryContainer/VehicleSummaryContainer';

class TicketContainer extends Component {
  static propTypes = {
    ticketId: PropTypes.string,
    previousPages: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    subscribe: PropTypes.func,
  };

  state = {
    loading: true,
    ticket: null,
    georgeVehicle: null,
    spireon: null,
    chargingForRepair: false,
    updateNavigation: 0,
    drivers: [],

    generatingInvoice: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.init();
    this.props.subscribe(
      ticketUpdatedSocket.subscribe(this.ticketUpdated),
      ticketDeletedSocket.subscribe(this.ticketDeleted)
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ticketId !== this.props.ticketId) this.init();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  storing = false;

  init = () => {
    this.loadTicket();
  };

  loadTicket = async () => {
    const {ticketId, history} = this.props;

    if (!readTicketPermission()) return history.replace(homeRoute());

    this.setState({loading: true});

    try {
      const ticket = await getTicketById(ticketId);
      const vehicle = await getVehicleByIdApi(fkOrId(ticket.vehicle));
      const georgeVehicle = await (!!vehicle.george_id
        ? (async () => {
            try {
              return getGeorgeVehicleByIdApi(vehicle.george_id);
            } catch (error) {
              return null;
            }
          })()
        : null);
      const driver = await (!!ticket.driver
        ? getDriverByIdApi(ticket.driver)
        : null);
      const {data: drivers} = await (!!vehicle.drivers.length
        ? api(listDriversApi, {id__in: vehicle.drivers.join(',')})
        : {data: []});
      if (!this.mounted) return;
      vehicle.origin = getVehicleOrigin(vehicle);
      ticket.driver = driver;
      ticket.vehicle = vehicle;
      setVehicleHistoryIdEvent.publish({vehicle});
      this.setState({
        loading: false,
        ticket,
        georgeVehicle,
        drivers,
      });
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      history.replace(homeRoute());
    }
  };

  ticketUpdated = (ticket) => {
    const {history} = this.props;
    const {ticket: currentTicket, loading} = this.state;
    if (
      loading ||
      currentTicket?.id !== ticket.id ||
      !this.mounted ||
      this.storing
    )
      return;
    if (!!currentTicket && !currentTicket.approved && ticket.approved) {
      alertify({message: 'Ticket got checked out by another user'});
      return history.replace(homeRoute());
    }
    const newTicket = {
      ...ticket,
      vehicle: currentTicket.vehicle,
      driver: currentTicket.driver,
    };
    this.setState({ticket: newTicket});
  };

  ticketDeleted = (ticket) => {
    const {history} = this.props;
    const {ticket: currentTicket, loading} = this.state;
    if (
      loading ||
      currentTicket?.id !== ticket.id ||
      !this.mounted ||
      this.storing
    )
      return;
    alertify({message: 'Ticket got deleted by another user'});
    history.replace(homeRoute());
  };

  showSelectServiceModal = () => {
    if (!updateTicketPermission()) return;
    showSelectServiceModalEvent.publish();
  };

  showChargeForItemsModal = () => {
    const {ticket} = this.state;
    showChargeModalEvent.publish({ticket, sync: this.syncTicket});
  };

  reopenTicket = async ({prompt = true} = {}) => {
    const {loading, ticket} = this.state;
    if (loading || !ticket || !updateTicketPermission()) return;

    if (prompt)
      return notify({
        title: `Reopen ticket #${ticket.id}`,
        content: 'Are you sure you want to reopen this ticket?',
        primary: {label: 'Cancel'},
        secondary: {
          label: 'Reopen',
          onClick: () => this.reopenTicket({prompt: false}),
        },
      });

    this.setState({loading: true});
    this.storing = true;

    try {
      await approveTicketApi(ticket.id, {
        approved: false,
      });
      const newTicket = {...ticket, approved: false};
      this.setState({loading: false, ticket: newTicket});
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      this.setState({loading: false});
    }

    this.storing = false;
  };

  ticketActions = () => {
    const {loading, ticket} = this.state;

    const isDeletable = isTicketDeletable(ticket);
    // const canChargeItems = canChargeForItems(ticket);

    return [
      !ticket?.approved && {
        key: 'payment',
        icon: 'credit-card-outline',
        label: !!ticket.payment_method
          ? `Paid via ${ticket.payment_method}`
          : 'Collect Payment',
        onClick: this.showPaidModal,
      },
      {
        key: 'statement',
        icon: 'receipt-text-outline',
        label: ticket?.approved ? 'Generate Invoice' : 'Generate Estimate',
        onClick: this.generateInvoice,
      },
      // canChargeItems && {
      //   key: 'charge',
      //   icon: 'currency-usd',
      //   label: 'Charge for items',
      //   onClick: this.showChargeForItemsModal,
      // },
      loading ||
      !ticket ||
      !updateTicketPermission() ||
      (!isDeletable &&
        !isTicketCheckoutable({
          ...ticket,
          hasPermission: approveCheckPermission(),
        }))
        ? null
        : {
            key: 'checkout',
            icon: isDeletable ? 'trash-can-outline' : 'keyboard-return',
            label: isDeletable ? 'Delete' : 'Checkout',
            onClick: isDeletable ? this.delete : this.checkout,
          },
      !isTicketEditable(ticket) &&
        updateTicketPermission() && {
          key: 'reopen',
          label: 'Reopen',
          icon: 'reload',
          onClick: isTicketEditable(ticket)
            ? this.showSelectServiceModal
            : this.reopenTicket,
        },
      {
        key: 'history',
        icon: 'clipboard-text-clock',
        label: 'Display History',
        onClick: () => showVehicleHistoryEvent.publish(),
      },
    ].filter((action) => !!action);
  };

  checkout = async ({prompt = true}) => {
    const {loading, ticket} = this.state;
    if (loading || !isTicketEditable(ticket) || !updateTicketPermission())
      return;

    if (!ticket.mileage) return showMileageEditModalEvent.publish();

    if (prompt)
      return notify({
        title: 'Checkout',
        content: ticket?.vehicle?.approved_for_work ? (
          ''
        ) : (
          <P theme="red" align="center" weight="bold" size="4">
            Vehicle is not approved.
            <br />
            Bill the driver.
          </P>
        ),
        primary: {label: 'Cancel'},
        secondary: {
          label: 'Checkout',
          onClick: () => this.checkout({prompt: false}),
        },
      });

    this.setState({loading: true});
    this.storing = true;

    try {
      const updatedTicket = await approveTicketApi(ticket.id, {
        approved: true,
      });
      const newTicket = {
        ...ticket,
        items: updatedTicket.items,
        approved: updatedTicket.approved,
        approved_at: updatedTicket.approved_at,
        approved_by: updatedTicket.approved_by,
      };
      this.setState({
        loading: false,
        ticket: newTicket,
        updateNavigation: new Date().getTime(),
      });
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      this.setState({loading: false});
    }

    this.storing = false;
  };

  delete = async ({prompt = true} = {}) => {
    const {history} = this.props;
    const {loading, ticket} = this.state;
    if (loading || !isTicketEditable(ticket) || !destroyTicketPermission())
      return;

    if (prompt)
      return notify({
        title: 'Are you sure?',
        content: '',
        primary: {label: 'Cancel'},
        secondary: {
          label: 'Delete',
          onClick: () => this.delete({prompt: false}),
        },
      });

    this.setState({loading: true});
    this.storing = true;

    try {
      await deleteTicketApi(ticket.id);
      alertify({
        title: 'Deleted',
        message: 'Ticket deleted, but Samson is always watching.',
      });
      history.replace(homeRoute());
    } catch (error) {
      const {message} = parseError(error);
      if (!this.mounted) return;
      alertify({message});
      this.setState({loading: false});
      this.storing = false;
    }
  };

  showPaidModal = () => {
    showTicketPaymentOptionsModalEvent.publish();
  };

  generateInvoice = async () => {
    const {generatingInvoice, ticket} = this.state;
    if (generatingInvoice) return;

    this.setState({generatingInvoice: true});

    try {
      const response = await generateTicketInvoiceApi(ticket.id);
      const file = blobToFile(response);
      downloadFile(file, `Invoice #${ticket.id}.pdf`, {newWindow: true});
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
    }

    if (!this.mounted) return;
    this.setState({generatingInvoice: false});
  };

  chargeForRepair = async ({prompt = true} = {}) => {
    const {chargingForRepair, ticket} = this.state;

    if (chargingForRepair || !ticket?.driver?.george_id) return;

    if (prompt)
      return notify({
        title: 'Charge for repair',
        content: [
          !!ticket?.external_charges?.length &&
            'Charge for this ticket already exists.',
          'Are you sure you want to create a repair charge for this ticket?',
        ]
          .filter((title) => !!title)
          .join(' '),
        primary: {label: 'Cancel'},
        secondary: {
          label: 'Charge',
          onClick: () => this.chargeForRepair({prompt: false}),
        },
      });

    this.setState({chargingForRepair: true});

    try {
      const updatedTicket = await generateRepairChargeApi(ticket.id);
      this.setState({
        chargingForRepair: false,
        ticket: {...ticket, external_charges: updatedTicket?.external_charges},
        updateNavigation: new Date().getTime(),
      });
    } catch (error) {
      const {message} = parseError(error);
      alertify({message});
      this.setState({chargingForRepair: false});
    }
  };

  syncTicket = (ticket) => {
    if (!this.mounted || this.state.ticket?.id !== ticket?.id) return;
    this.setState({ticket, updateNavigation: new Date().getTime()});
  };

  syncData = (data = {}) => {
    if (!this.mounted) return;
    this.setState(data);
  };

  render() {
    const {locations} = this.props;
    const {
      loading,
      ticket,
      georgeVehicle,
      spireon,
      drivers,

      chargingForRepair,

      generatingInvoice,
    } = this.state;

    return loading || chargingForRepair || generatingInvoice ? (
      <PageLoader />
    ) : (
      <Container>
        <Row>
          <Column margin>
            <TicketAlertsContainer
              ticket={ticket}
              georgeVehicle={georgeVehicle}
            />
          </Column>
          <Column size={1 / 3} margin>
            <TicketEditContainer
              ticketActions={
                <TicketActionsRow actions={this.ticketActions()} />
              }
              drivers={drivers}
              syncTicket={this.syncTicket}
              ticket={ticket}
              notes={
                <NotesContainer syncTicket={this.syncTicket} ticket={ticket} />
              }
              externalId={
                <ExternalIdContainer
                  syncTicket={this.syncTicket}
                  ticket={ticket}
                />
              }
              externalInvoice={<ExternalInvoiceContainer ticket={ticket} />}
              actions={
                <TicketActionsContainer
                  georgeVehicle={georgeVehicle}
                  onSync={this.syncData}
                  ticket={ticket}
                />
              }
              tags={<TicketTagEditorContainer ticket={ticket} />}
              locations={locations}
            />
          </Column>
          <Column size={2 / 3}>
            <Row>
              <Column margin>
                <TicketNotesContainer
                  ticket={ticket}
                  syncTicket={this.syncTicket}
                />
              </Column>
              <Column margin>
                <TicketItemsContainer
                  syncTicket={this.syncTicket}
                  ticket={ticket}
                />
                <VehicleSummaryContainer
                  georgeVehicle={georgeVehicle}
                  spireon={spireon}
                  ticket={ticket}
                />
              </Column>
              <Column margin>
                <RepairImagesContainer ticket={ticket} />
              </Column>
            </Row>
          </Column>
          <Column margin>
            <MileageContainer syncTicket={this.syncTicket} ticket={ticket} />
            <TicketMechanicsContainer
              syncTicket={this.syncTicket}
              ticket={ticket}
            />
            <SelectServiceContainer />
            <TicketPaymentOptionsContainer
              syncTicket={this.syncTicket}
              ticket={ticket}
            />
            <ChargeItemsContainer />
          </Column>
        </Row>
      </Container>
    );
  }
}

export default subscriptionHoc(
  withRouter(
    connect((state) => ({
      previousPages: state.page.previousPages,
      ticketStatuses: state.ticketStatus.ticketStatuses,
      locations: state.location.locations,
    }))(TicketContainer)
  )
);
